.homepage {
  height: 100%;
  display: grid;
  grid-template-rows: 1fr 3fr;
}

.homepage_information {
  display: grid;
  grid-template-columns: 1fr 1.4fr 1fr;

  grid-gap: 0.5rem;

  margin: 40px;
}

.homepage_information_1,
.homepage_information_2,
.homepage_information_3 {
  display: grid;
  grid-template-rows: 1fr 2fr 1fr;
}

.hm_info_title,
.hm_info_footer,
.hm_info_content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.hm_info_title > p {
  color: #394e6a;

  font-size: 14px;
}

.hm_info_footer > p {
  color: #394e6a;

  font-size: 12px;
}

.hm_info_content > p {
  color: #394e6a;

  font-size: 40px;
}

.homepage_information > div {
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(85, 89, 223, 0.2);
}
