nav {
  grid-area: nav;
}

.topnav {
  overflow: hidden;
  display: flex;
  justify-content: space-between;
}

.topnav a {
  float: left;
  display: block;
  color: #394e6a;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
  border-radius: 8px;
}

.topnav a:hover {
  opacity: 0.5;
}

.topnav .icon {
  display: none;
}
