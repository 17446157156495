$font-family-base: 'Roboto', sans-serif;

$input-focus-box-shadow: none;
$btn-box-shadow: none;
$btn-focus-box-shadow: none;

.description {
  font-size: 13px !important;
  line-height: 18px;
  color: #222;
}

@import 'bootstrap';
