.multisub {
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
}

.multisub_wrapper {
  width: 90%;
  height: 50%;
  min-height: 360px;

  display: grid;
  grid-template-rows: 2fr 4fr;
}

.multisub_title {
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 40px;

  color: #394e6a;
}

.multisub_form {
  display: grid;
  grid-template-rows: 4fr 1.5fr;
}

.multisub_form_inputs {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.multisub_form_inputs > input,
select {
  width: 40%;
  height: 25%;
  border-radius: 10px;
  border: 1px solid #394e6a;

  text-align: center;

  color: #394e6a;
  background-color: transparent;
}

.multisub_form_inputs > input,
select:focus {
  outline: none;
}

.multisub_form_option {
  color: #394e6a;
}

.multisub_form_button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.multisub_form_button > input {
  width: 20%;
  height: 60%;

  background-color: transparent;
  color: #394e6a;
  border-radius: 10px;
  border: 1px solid #394e6a;

  font-size: 14px;

  cursor: pointer;

  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
}

.multisub_form_button > input:hover {
  background-color: #394e6a;
  color: #ffffff;

  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
}
