* {
  margin: 0;
  padding: 0;
  text-decoration: none;
}

body,
input,
button {
  font-family: 'Albert Sans', sans-serif;
}

body {
  background-color: #f5f5f5 !important;
}

@media screen and (max-width: 900px) {
  .topnav {
    display: block;
  }

  .topnav a:not(:first-child) {
    display: none;
  }

  .navMid a {
    display: none;
  }

  .navEnd a {
    display: none;
  }

  .topnav a.icon {
    float: right;
    display: block;
  }

  .topnav.responsive .icon {
    position: absolute;
    right: 0;
    top: 0;
  }
  .topnav.responsive a {
    float: none;
    display: block;
    text-align: left;
  }
  .support_wrapper {
    height: 80%;
    grid-template-columns: 1fr;
  }
  .support_content {
    padding: 0;
  }
  .login_wrapper {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;

    height: 70%;
  }
  .redirect > p {
    font-size: 20px;
  }
}

@media screen and (max-width: 700px) {
  .homepage {
    grid-template-rows: 1fr 1.3fr;
  }

  .homepage_information {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
  }
  .multisub_title {
    font-size: 25px;
  }
}

.dropdown-toggle::after {
  content: none !important;
}
