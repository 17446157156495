.support {
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
}

.support_wrapper {
  width: 80%;
  height: 30%;

  display: grid;
  grid-template-columns: 1fr 1.5fr;
}

.support_images {
  display: flex;
  align-items: center;
  justify-content: center;
}

.support_images > img {
  width: 100%;
}

.support_content {
  display: grid;
  grid-template-rows: 1fr 2fr 1fr;

  padding-left: 20px;
}

.support_content_title {
  display: flex;
  align-items: center;
}

.support_content_title > p {
  font-size: 30px;

  color: #394e6a;
}

.support_content_content {
  min-width: 300px;
  display: flex;
  align-items: center;
}

.support_content_button {
  display: flex;
  align-items: center;
}

.support_content_button > button {
  width: 200px;
  height: 40px;

  background-color: transparent;

  color: #394e6a;
  border-radius: 10px;
  border: 1px solid #394e6a;

  font-size: 14px;

  cursor: pointer;

  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
}

.support_content_button > button:hover {
  background-color: #394e6a;
  color: #ffffff;

  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
}
